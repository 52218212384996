import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {consentToDataPrivacyTimestamp} from '@shared/const/local-storage-keys';
import {msInHour} from '@shared/const/ms-in-hour';

@Component({
  selector: 'app-consent-dialog',
  templateUrl: './consent-dialog.component.html',
  styleUrls: ['./consent-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentDialogComponent implements OnInit {
  visible = false;
  condition1 = false;
  condition2 = false;
  condition3 = false;
  areSwitchesChecked = false;
  private keycloakService = inject(KeycloakService);
  private readonly DEADLINE = Date.now() - 2 * msInHour;

  ngOnInit(): void {
    const consentTimestamp = localStorage.getItem(consentToDataPrivacyTimestamp);
    const hasConsent = consentTimestamp ? parseInt(consentTimestamp, 10) >= this.DEADLINE : false;
    if (!hasConsent) {
      this.visible = true;
      localStorage.removeItem(consentToDataPrivacyTimestamp);
    }
  }

  logout() {
    this.keycloakService.logout();
  }

  confirm() {
    localStorage.setItem(consentToDataPrivacyTimestamp, Date.now().toString());
    this.visible = false;
  }

  checkConsent() {
    this.areSwitchesChecked = this.condition1 && this.condition2 && this.condition3;
  }
}
