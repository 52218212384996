import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConsentDialogComponent} from './consent-dialog.component';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {FormsModule} from '@angular/forms';
import {InputSwitchModule} from 'primeng/inputswitch';

@NgModule({
  declarations: [ConsentDialogComponent],
  exports: [ConsentDialogComponent],
  imports: [CommonModule, ButtonModule, DialogModule, FormsModule, InputSwitchModule],
})
export class ConsentDialogModule {}
