import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, shareReplay} from 'rxjs/operators';
import {combineLatest, map} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {KeycloakService} from 'keycloak-angular';
import {CONTROL_CENTER_ROLES, READ_DATA_ROLES} from '@app/roles';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import {faMap} from '@fortawesome/free-solid-svg-icons/faMap';
import {faChartLine} from '@fortawesome/free-solid-svg-icons/faChartLine';
import {faClipboardList} from '@fortawesome/free-solid-svg-icons/faClipboardList';
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import {faGamepad} from '@fortawesome/free-solid-svg-icons/faGamepad';
import {faDoorOpen} from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import {consentToDataPrivacyTimestamp} from '@shared/const/local-storage-keys';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent implements OnInit {
  isMenuFixed = false;
  isVisible = false;
  controlCenterRoles = CONTROL_CENTER_ROLES;
  readDataRoles = READ_DATA_ROLES;

  protected readonly faBars = faBars;
  protected readonly faMap = faMap;
  protected readonly faChartLine = faChartLine;
  protected readonly faClipboardList = faClipboardList;
  protected readonly faCheckSquare = faCheckSquare;
  protected readonly faGamepad = faGamepad;
  protected readonly faDoorOpen = faDoorOpen;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private keycloakService: KeycloakService,
    private destroyRef: DestroyRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const isHandset$ = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Handset, Breakpoints.TabletPortrait, Breakpoints.TabletLandscape])
      .pipe(
        map((result) => result.matches),
        shareReplay(),
      );

    const isMapView$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(
        map((event: NavigationEnd) => event.urlAfterRedirects.includes('map') || event.url.includes('registration')),
      );
    combineLatest([isHandset$, isMapView$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isHandset, isMapView]) => {
        this.isMenuFixed = !isHandset && !isMapView;
        this.changeDetectorRef.detectChanges();
      });
  }

  onLogout() {
    this.keycloakService.logout();
    localStorage.removeItem(consentToDataPrivacyTimestamp);
  }

  checkHasAnyRole(roles: string[]) {
    return roles.some((role) => this.keycloakService.getUserRoles().includes(role));
  }

  checkHasAllRoles(roles: string[]) {
    return roles.every((role) => this.keycloakService.getUserRoles().includes(role));
  }
}
