import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from '../pages/app-routing.module';
import {AppComponent} from './app.component';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {AppConfigService} from './app-config.service';
import {Observable} from 'rxjs';
import {AppConfig} from '@shared/models/app-config.interface';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsModule} from '@ngxs/store';
import {environment} from '../environments/environment';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {ngxsStates} from './store/ngxs.states';
import {MainMenuModule} from '../widgets/main-menu/main-menu.module';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {switchMap} from 'rxjs/operators';
import {IAuthConfig} from '@app/auth-config.interface';
import {MessageService} from 'primeng/api';
import {HttpReqInterceptor} from '@app/interceptors/httpReq.interceptor';
import {ConsentDialogModule} from '../widgets/consent-dialog/consent-dialog.module';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

function initializeApp(keycloak: KeycloakService, appConfigService: AppConfigService): () => Observable<AppConfig> {
  return () =>
    appConfigService.loadAuthConfig().pipe(
      switchMap((authConfig) => initializeKeycloak(keycloak, authConfig)),
      switchMap((response) => {
        console.log(response);
        return appConfigService.loadAppConfig();
      }),
    );
}

function initializeKeycloak(keycloak: KeycloakService, {url, realm, clientId}: IAuthConfig): Promise<boolean> {
  const keycloakInstance = keycloak.getKeycloakInstance();
  console.log(keycloakInstance?.sessionId);
  console.log(keycloakInstance?.idToken);
  return keycloak.init({
    config: {url, realm, clientId},
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: false,
    },
    enableBearerInterceptor: true,
    bearerExcludedUrls: ['https://www.openstreetmap.org/api/'],
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    NgxsModule.forRoot(ngxsStates, {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false,
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    MainMenuModule,
    ConsentDialogModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpReqInterceptor, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [KeycloakService, AppConfigService],
    },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
