<p-dialog header="I confirm to adhere to:" [modal]="true" [(visible)]="visible" [style]="{width: '25rem'}">
  <ul>
    <li class="flex align-items-center gap-3 mb-4">
      <p-inputSwitch [(ngModel)]="condition1" id="switch-condition-1" (onChange)="checkConsent()" />
      <label for="switch-condition-1">data privacy regulations</label>
    </li>
    <li class="flex align-items-center gap-3 mb-4">
      <p-inputSwitch [(ngModel)]="condition2" id="switch-condition-2" (onChange)="checkConsent()" />
      <label for="switch-condition-2">confidentiality</label>
    </li>
    <li class="flex align-items-center gap-3 mb-4">
      <p-inputSwitch [(ngModel)]="condition3" id="switch-condition-3" (onChange)="checkConsent()" />
      <label for="switch-condition-3">ethical requirements</label>
    </li>
  </ul>

  <div class="flex justify-content-end gap-3">
    <p-button label="Logout" severity="secondary" (onClick)="logout()" />
    <p-button [disabled]="!areSwitchesChecked" label="Confirm" (onClick)="confirm()" />
  </div>
</p-dialog>
