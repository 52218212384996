import {IMapIconObject, ITrafficObject} from 'viz-common';
import {IObjectRef} from '../state/shared.state';

export namespace SharedActions {
  export class ActivateObject {
    static readonly type = '[SHARED] Activate Object';

    constructor(public object: IObjectRef | null) {}
  }

  export class SetActiveList {
    static readonly type = '[SHARED] SetActiveList';

    constructor(public listName: string | null) {}
  }

  export class AddRelatedObject {
    static readonly type = '[SHARED] AddRelatedObject';

    constructor(public relatedObject: ITrafficObject) {}
  }

  export class SetObjectIsBeingEditedStatus {
    static readonly type = '[OBJECT_IS_BEING_EDITED] Set';

    constructor(public isBeingEdited: boolean) {}
  }

  export class SetObjectIsBeingCreatedStatus {
    static readonly type = '[OBJECT_IS_BEING_CREATED] Set';

    constructor(public isBeingCreated: boolean) {}
  }

  export class SetUserAsIdle {
    static readonly type = '[IS_USER_IDLE] Set';

    constructor(public isUserIdle: boolean) {}
  }

  export class SetPreviousObject {
    static readonly type = '[PREVIOUS_OBJECT] Set';

    constructor(public previousTrafficObject: IMapIconObject) {}
  }

  export class SetMeasurementStopTime {
    static readonly type = '[MEASUREMENT_STOP_TIME] Set';

    constructor(public measurementStopTime: Date) {}
  }

  export class StartTimescaleAnimation {
    static readonly type = '[TIMESCALE_ANIMATION] Set';

    constructor(public timescaleAnimationOn: boolean) {}
  }

  export class SetCurrentProjection {
    static readonly type = '[CURRENT_PROJECTION] Set';

    constructor(public currentProjection: string) {}
  }

  export class SetGlobalSearchZIndex {
    static readonly type = '[GLOBAL_SEARCH] SET_ZIndex';

    constructor(public zIndex: number) {}
  }
}
