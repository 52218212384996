import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {IMapIconObject, ITrafficObject} from 'viz-common';
import {SharedActions} from '../actions/shared.actions';
import SetActiveList = SharedActions.SetActiveList;
import AddRelatedObject = SharedActions.AddRelatedObject;
import SetObjectIsBeingEditedStatus = SharedActions.SetObjectIsBeingEditedStatus;
import ActivateObject = SharedActions.ActivateObject;
import SetUserAsIdle = SharedActions.SetUserAsIdle;
import SetObjectIsBeingCreatedStatus = SharedActions.SetObjectIsBeingCreatedStatus;
import SetPreviousObject = SharedActions.SetPreviousObject;
import SetMeasurementStopTime = SharedActions.SetMeasurementStopTime;
import SetCurrentProjection = SharedActions.SetCurrentProjection;
import ToggleTimescaleAnimation = SharedActions.StartTimescaleAnimation;
import SetGlobalSearchZIndex = SharedActions.SetGlobalSearchZIndex;

export interface IObjectRef {
  id: string;
  type: string;
}

export class SharedStateModel {
  activeListName!: string | null;
  relatedObject!: ITrafficObject | null;
  isBeingEdited!: boolean;
  isBeingCreated!: boolean;
  activeObject!: IObjectRef | null;
  isUserIdle!: boolean;
  previousTrafficObject!: IMapIconObject | null;
  measurementStopTime!: Date | null;
  timescaleAnimationOn!: boolean;
  currentProjection!: string | null;
  globalSearchZIndex!: number;
}

@State<SharedStateModel>({
  name: 'shared',
  defaults: {
    activeListName: null,
    relatedObject: null,
    isBeingEdited: false,
    isBeingCreated: false,
    activeObject: null,
    isUserIdle: false,
    previousTrafficObject: null,
    measurementStopTime: null,
    timescaleAnimationOn: false,
    currentProjection: 'EPSG:3857',
    globalSearchZIndex: 12000,
  },
})
@Injectable()
export class SharedState {
  @Action(ActivateObject)
  ActivateObject({patchState}: StateContext<SharedStateModel>, {object}: ActivateObject) {
    patchState({
      activeObject: object ? {id: object.id, type: object.type} : null,
    });
  }

  @Action(SetActiveList)
  SetActiveList({patchState}: StateContext<SharedStateModel>, {listName}: SetActiveList) {
    patchState({
      activeListName: listName,
    });
  }

  @Action(AddRelatedObject)
  AddRelatedObject({patchState}: StateContext<SharedStateModel>, {relatedObject}: AddRelatedObject) {
    patchState({
      relatedObject,
    });
  }

  @Action(SetObjectIsBeingEditedStatus)
  setObjectIsBeingEditedStatus(
    {patchState}: StateContext<SharedStateModel>,
    {isBeingEdited}: SetObjectIsBeingEditedStatus,
  ) {
    patchState({
      isBeingEdited,
      globalSearchZIndex: isBeingEdited ? 9000 : 12000,
    });
  }

  @Action(SetObjectIsBeingCreatedStatus)
  setObjectIsBeingCreatedStatus(
    {patchState}: StateContext<SharedStateModel>,
    {isBeingCreated}: SetObjectIsBeingCreatedStatus,
  ) {
    patchState({
      isBeingCreated: isBeingCreated,
    });
  }

  @Action(SetUserAsIdle)
  setUserAsIdle({patchState}: StateContext<SharedStateModel>, {isUserIdle}: SetUserAsIdle) {
    patchState({
      isUserIdle,
    });
  }

  @Action(SetPreviousObject)
  SetPreviousObject({patchState}: StateContext<SharedStateModel>, {previousTrafficObject}: SetPreviousObject) {
    patchState({
      previousTrafficObject,
    });
  }

  @Action(SetMeasurementStopTime)
  SetMeasurementStopTime({patchState}: StateContext<SharedStateModel>, {measurementStopTime}: SetMeasurementStopTime) {
    patchState({
      measurementStopTime,
    });
  }

  @Action(ToggleTimescaleAnimation)
  ToggleTimescaleAnimation(
    {patchState}: StateContext<SharedStateModel>,
    {timescaleAnimationOn}: ToggleTimescaleAnimation,
  ) {
    patchState({timescaleAnimationOn});
  }

  @Action(SetCurrentProjection)
  SetCurrentProjection({patchState}: StateContext<SharedStateModel>, {currentProjection}: SetCurrentProjection) {
    patchState({
      currentProjection,
    });
  }

  @Action(SetGlobalSearchZIndex)
  SetSidebarZIndex({patchState}: StateContext<SharedStateModel>, {zIndex}: SetGlobalSearchZIndex) {
    patchState({
      globalSearchZIndex: zIndex,
    });
  }
}
